import { useState, useEffect } from 'react'
import Config from './../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, Avatar, Select, Image, Upload, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined, EyeOutlined, MoneyCollectOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { cpf as CPFValid } from 'cpf-cnpj-validator';
import Icon from '@mdi/react';
import { mdiCashRegister, mdiCashMinus, mdiCashPlus, mdiCashRemove, mdiClipboardList } from '@mdi/js';
import './style.css';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
const { Search } = Input;
const moment = require('moment-timezone');

function Pacientes() {
  const [api, contextHolder] = notification.useNotification();
  const [Novo, setNovo] = useState(false);
  const [Fatura, setFatura] = useState(false);
  const [NovoFatura, setNovoFatura] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Pacientes, setPacientes] = useState([]);
  const [Faturas, setFaturas] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Foto, setFoto] = useState('');
  const [Nome, setNome] = useState('');
  const [Cpf, setCpf] = useState('');
  const [Sexo, setSexo] = useState('');
  const [Nascimento, setNascimento] = useState('');
  const [Telefone, setTelefone] = useState('');
  const [Whatsapp, setWhatsapp] = useState(null);
  const [Endereco, setEndereco] = useState('');
  const [Numero, setNumero] = useState('');
  const [Bairro, setBairro] = useState('');
  const [Estado, setEstado] = useState('');
  const [Cidade, setCidade] = useState('');
  const [EstadoCivil, setEstadoCivil] = useState('');
  const [Profissao, setProfissao] = useState('');
  const [Referencia, setReferencia] = useState('');

  const [IdFatura, setIdFatura] = useState('');
  const [Status, setStatus] = useState('');
  const [Titulo, setTitulo] = useState('');
  const [Data, setData] = useState('');
  const [ValorProcedimento, setValorProcedimento] = useState('');
  const [Pago, setPago] = useState('');
  const [ValorPago, setValorPago] = useState('');
  const [Cortesia, setCortesia] = useState('');
  const [Observacao, setObservacao] = useState('');


  useEffect(() => {

    if (Status === 'Faltou') {
      setValorPago('');
      setPago('');
      setCortesia('');
    }

    if (Pago === 'Não') {
      setValorPago('');
    }

    if (Pago === 'Sim') {
      setCortesia('');
    }

    if (Cortesia === 'Sim') {
      setValorPago('');
    }

  }, [Status, Pago])

  async function Listar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarPacientesFiltro',
      data: {
        Usuario, Buscar: `%${Buscar}%`
      }
    }).then(res => {
      if (res.data.sucesso) {
        setPacientes(res.data.result);
      } else {
        setPacientes([]);
      };
    });
  };

  async function ListarFaturas(Model) {
    setNome(Model.Nome);
    setId(Model.Id);
    axios({
      method: 'post',
      url: Config.Url + 'ListarFaturaCli',
      data: {
        Paciente: Model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setFatura(true);
        setFaturas(res.data.result);
      } else {
        setFatura(true);
        setFaturas([]);
      };
    });
  };

  function FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];
    return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  }

  function AbrirRegistroFatura(model) {
    setIdFatura(model.Id);
    setStatus(model.Status_comp);
    setTitulo(model.Titulo);
    setData(FormataStringData(model.Data));
    setValorProcedimento(model.Procedimento);
    setPago(model.Status_pg);
    setValorPago(model.Valor_pg);
    setCortesia(model.Cortesia);
    setObservacao(model.Descricao);
    setNovoFatura(true)
  };

  function AbrirRegistro(model) {
    setId(model.Id);
    setFoto(model.Foto);
    setNome(model.Nome);
    setCpf(model.Cpf);
    setSexo(model.Sexo);
    setNascimento(FormataStringData(model.Nascimento));
    setTelefone(model.Telefone);
    setWhatsapp(model.Whatsapp === 1 ? true : false);
    setEndereco(model.Endereco);
    setNumero(model.Numero);
    setBairro(model.Bairro);
    setEstado(model.Estado);
    setCidade(model.Cidade);
    setEstadoCivil(model.Estado_civil);
    setProfissao(model.Profissao);
    setReferencia(model.Referencia);
    setNovo(true);
  };

  function LimpaCampos() {
    setId('');
    setFoto('');
    setNome('');
    setCpf('');
    setSexo('');
    setNascimento('');
    setTelefone('');
    setWhatsapp(null);
    setEndereco('');
    setNumero('');
    setBairro('');
    setEstado('');
    setCidade('');
    setEstadoCivil('');
    setProfissao('');
    setReferencia('');
  };

  function LimpaCamposFatura() {
    setIdFatura('')
    setStatus('');
    setTitulo('');
    setData();
    setValorProcedimento('');
    setPago('');
    setValorPago('');
    setCortesia('');
    setObservacao('');
  };

  async function CriarPronturario(params) {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoProntuariosRapido',
      data: {
        "Paciente": params,
        "Usuario": Usuario
      }
    });
  };

  async function Salvar(model) {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoGestao',
      data: {
        Titulo: model.Titulo,
        Descricao: model.Descricao,
        Tipo: 'Receita',
        Valor: model.Valor_pg,
        Data: model.Data,
        Usuario: Usuario,
        Fatura: model.Id,
        Paciente: model.Paciente
      }
    })
  };

  async function CadastrarFatura() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoFaturaCli',
      data: {
        Titulo: Titulo,
        Descricao: Observacao,
        Procedimento: ValorProcedimento === '' ? 'R$0,00' : 'R$' + ValorProcedimento,
        Valor_pg: ValorPago === '' ? 'R$0,00' : 'R$' + ValorPago,
        Status_pg: Pago,
        Status_comp: Status,
        Cortesia: Cortesia,
        Data: moment(Data).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        Paciente: Id,
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        if (Pago === 'Sim') {
          Salvar(res.data.result);
        };
        setNovoFatura(false)
        ListarFaturas({ Id: Id, Nome: Nome });
        message.success('Fatura salva com sucesso');
        LimpaCamposFatura()
      } else {
        message.error(res.data.mensagem);
      };
    });

  };

  async function Cadastrar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoPacientes',
      data: {
        Foto: Foto,
        Nome: Nome,
        Cpf: Cpf,
        Sexo: Sexo,
        Nascimento: moment(Nascimento).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        Telefone: Telefone,
        Whatsapp: Whatsapp,
        Endereco: Endereco,
        Numero: Numero,
        Bairro: Bairro,
        Estado: Estado,
        Cidade: Cidade,
        Estado_civil: EstadoCivil,
        Profissao: Profissao,
        Referencia: Referencia,
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        LimpaCampos()
        setNovo(false);
        CriarPronturario(res.data.result['Id']);
        message.success('Paciente cadastrado com sucesso');
      } else {
        message.error(res.data.mensagem);
      };
    });
  };

  async function Delete(model) {
    api.destroy(model.Id);
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarPacientes',
      data: {
        Id: model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setTimeout(() => {
          Listar();
        }, 2500);
        LimpaCampos()
        message.success('Paciente excluído com sucesso');
      } else {
        Listar();
        message.error('Falha ao excluir paciente');
      };
    });
  };

  function MensagemDelete(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Delete(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }

  async function DeleteFatura(model) {
    api.destroy(model.Id);
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarFaturaCli',
      data: {
        Id: model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        axios({
          method: 'delete',
          url: Config.Url + 'DeletarGestaoPorFatura',
          data: {
            Fatura: model.Id
          }
        })
        setTimeout(() => {
          ListarFaturas({ Id: Id, Nome: Nome });
        }, 2500);
        message.success('Fatura exluida com sucesso');
      } else {
        message.error('Falha ao excluir fatura');
      };
    });
  };

  function MensagemDeleteFatura(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeleteFatura(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }

  async function Update() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'put',
      url: Config.Url + 'EditarPacientes',
      data: {
        Id: Id,
        Foto: Foto,
        Nome: Nome,
        Cpf: Cpf,
        Sexo: Sexo,
        Nascimento: moment(Nascimento).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        Telefone: Telefone,
        Whatsapp: Whatsapp,
        Endereco: Endereco,
        Numero: Numero,
        Bairro: Bairro,
        Estado: Estado,
        Cidade: Cidade,
        Estado_civil: EstadoCivil,
        Profissao: Profissao,
        Referencia: Referencia,
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        LimpaCampos()
        setNovo(false);
        message.success('Paciente editado com sucesso');
      } else {
        message.error(res.data.mensagem);
      };
    });
  };

  async function UpdateGestao(model) {
    axios({
      method: 'put',
      url: Config.Url + 'EditarGestaoProFatura',
      data: {
        Titulo: model.Titulo,
        Descricao: model.Descricao,
        Tipo: 'Receita',
        Valor: model.Valor_pg,
        Data: model.Data,
        Fatura: model.Id
      }
    })
  };

  async function UpdateFatura() {
    axios({
      method: 'put',
      url: Config.Url + 'EditarFaturaCli',
      data: {
        Id: IdFatura,
        Titulo: Titulo,
        Descricao: Observacao,
        Procedimento: ValorProcedimento === '' ? 'R$0,00' : 'R$' + ValorProcedimento.replace('R$', ''),
        Valor_pg: Pago === 'Não' || ValorPago === '' ? 'R$0,00' : 'R$' + ValorPago.replace('R$', ''),
        Status_pg: Pago,
        Status_comp: Status,
        Cortesia: Cortesia,
        Data: moment(Data).tz("America/Sao_Paulo").format('DD/MM/YYYY')
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        if (Pago === 'Sim') {
          UpdateGestao(res.data.result);              //<- remover> esse trecho de código?
          Faturas.filter(e => e.Id === IdFatura)
            .map(e => {
              if (e.Status_pg === 'Não') {
                // avoid duplicated
                //Salvar(res.data.result)
              }
            })
        } else {
          axios({
            method: 'delete',
            url: Config.Url + 'DeletarGestaoPorFatura',
            data: {
              Fatura: res.data.result.Id
            }
          })
        }
        setNovoFatura(false)
        ListarFaturas({ Id: Id, Nome: Nome });
        LimpaCamposFatura()
        message.success('Fatura editada com sucesso');
      } else {
        message.error(res.data.mensagem);
      };
    });
  };

  useEffect(() => {
    Listar();
  }, [Buscar]);

  async function AbrirWhatsapp(Tel, zap) {
    const Pais = await localStorage.getItem('Pais');
    let Telefone = Tel;
    Telefone = Telefone.replace(/[ÀÁÂÃÄÅ]/g, '');
    Telefone = Telefone.replace(/[àáâãäå]/g, '');
    Telefone = Telefone.replace(/[ÈÉÊË]/g, '');
    Telefone = Telefone.replace(/[^a-z0-9]/gi, '');

    if (zap === true) {
      window.open('https://wa.me/' + Pais + Telefone, "_blank");
    }

  }

  const columns = [
    {
      title: 'FOTO',
      dataIndex: 'Foto',
      key: 'Foto',
      render: (dataIndex,) =>
        <di>
          <Avatar size={60} src={<Image style={{ width: 60, height: 60, borderRadius: 60 }} src={dataIndex === '' ? require('./../../Images/semimagem.png') : Config.Url + 'Pacientes/' + dataIndex} />} />
        </di>
    },
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'NASCIMENTO',
      dataIndex: 'Nascimento',
      key: 'Nascimento',
    },
    {
      title: 'TELEFONE',
      dataIndex: 'Telefone',
      key: 'Telefone',
      render: (dataIndex, key) =>
        <a onClick={() => AbrirWhatsapp(dataIndex, key['Whatsapp'])}><text>{dataIndex}</text></a>

    },
    {
      title: 'SEXO',
      dataIndex: 'Sexo',
      key: 'Sexo',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => ListarFaturas(key)} title='Faturas' type="primary" style={{ backgroundColor: 'green' }} shape="circle" icon={<MoneyCollectOutlined />} />
          <Button onClick={() => MensagemDelete(key)} title='Excluir' type="primary" danger shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  function ChecaStatusPG(model) {
    if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Sim' && LimpaCaracteresParseFloat(model.Valor_pg) >= LimpaCaracteresParseFloat(model.Procedimento)) {
      return '#b1ff00'; // Pago
    } else if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Sim' && LimpaCaracteresParseFloat(model.Valor_pg) < LimpaCaracteresParseFloat(model.Procedimento) && LimpaCaracteresParseFloat(model.Valor_pg) > 0) {
      return '#ff7d00'; // Parcial
    } else if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Não' && model.Cortesia === 'Não') {
      return '#f00000'; // Não Pago
    } else if (model.Status_comp === 'Compareceu' && model.Cortesia === 'Sim') {
      return '#744700'; // Cortesia
    } else if (model.Status_comp === 'Faltou') {
      return '#016C9A'; // Falta
    } else {
      return '#f00000'; // Não Pago
    }
  };

  function RetornaSratus(model) {
    if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Sim' && LimpaCaracteresParseFloat(model.Valor_pg) >= LimpaCaracteresParseFloat(model.Procedimento)) {
      return 'Pago' // Pago
    } else if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Sim' && LimpaCaracteresParseFloat(model.Valor_pg) < LimpaCaracteresParseFloat(model.Procedimento) && LimpaCaracteresParseFloat(model.Valor_pg) > 0) {
      return 'Pago Parcialmente'  // Parcial
    } else if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Sim' && LimpaCaracteresParseFloat(model.Valor_pg) === 0) {
      return 'Não Pago'  // Não Pago
    } else if (model.Status_comp === 'Compareceu' && model.Status_pg === 'Não' && model.Cortesia === 'Não') {
      return 'Não Pago' // Não Pago
    } else if (model.Status_comp === 'Compareceu' && model.Cortesia === 'Sim') {
      return 'Cortesia' // Cortesia
    } else if (model.Status_comp === 'Faltou') {
      return 'Falta' // Falta
    } else {
      return 'Não Pago'
    }
  };

  const columnsFaturas = [
    {
      title: 'STATUS',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <div title={RetornaSratus(key)} style={{ width: 30, height: 30, borderRadius: 30, backgroundColor: ChecaStatusPG(key) }} />
    },
    {
      title: 'DATA',
      dataIndex: 'Data',
      key: 'Data',
    },
    {
      title: 'TÍTULO',
      dataIndex: 'Titulo',
      key: 'Titulo',
    },
    {
      title: 'PROCEDIMENTO',
      dataIndex: 'Procedimento',
      key: 'Procedimento',
    },
    {
      title: 'VALOR PAGO',
      dataIndex: 'Valor_pg',
      key: 'Valor_pg',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistroFatura(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemDeleteFatura(key)} title='Excluir' type="primary" danger shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  function numberToReal(numero) {
    var numero = parseFloat(numero).toFixed(2).split('.');
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  };

  function formatarMoeda(params) {
    var valor = params;
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor < 99 ? valor.replace(/([0-9]{2})$/g, "0,$1") : valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }
    return params === '' ? '0,00' : valor;
  };

  function LimpaCaracteresParseFloat(text) {
    const res = text.replace('R$', '').replace('.', '').replace(',', '.');
    return parseFloat(res);
  };

  //#region FiltrarHistorico
  function FiltrarHistorico() {
    const res = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim') {
        return e
      };
    });
    const Valor = res.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));
    var sum = 0;
    for (var i = 0; i < Valor.length; i++) {
      sum += Valor[i];
    };
    const Result = parseFloat(sum);
    return Result;
  };
  //#endregion

  //#region FiltrarDebitos
  function FiltrarDebitos() {
    const res1 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Não' && e.Cortesia === 'Não') {
        return e
      };
    });
    const res2 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) < LimpaCaracteresParseFloat(e.Procedimento) && LimpaCaracteresParseFloat(e.Valor_pg) > 0) {
        return e
      };
    });

    const res3 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) > LimpaCaracteresParseFloat(e.Procedimento)) {
        return e
      };
    });

    const Valor1 = res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
    const Valor2 = res2.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
    const Valor3 = res2.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));

    const Valor4 = res3.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));
    const Valor5 = res3.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    var sum2 = 0;
    var sum3 = 0;
    var sum4 = 0;
    var sum5 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };
    for (var i = 0; i < Valor2.length; i++) {
      sum2 += Valor2[i];
    };
    for (var i = 0; i < Valor3.length; i++) {
      sum3 += Valor3[i];
    };
    for (var i = 0; i < Valor4.length; i++) {
      sum4 += Valor4[i];
    };
    for (var i = 0; i < Valor5.length; i++) {
      sum5 += Valor5[i];
    };
    const Diferenca = parseFloat(sum4) - parseFloat(sum5);
    const Soma = parseFloat(sum2) - parseFloat(sum3);
    const Calculo = (parseFloat(sum1) + parseFloat(Soma)) - parseFloat(Diferenca);
    const Result = Calculo <= 0 ? 0 : Calculo;
    return Result;
  };
  //#endregion

  //#region FiltrarCredito
  function FiltrarCredito() {
    const res1 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Não' && e.Cortesia === 'Não') {
        return e
      };
    });
    const res2 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) < LimpaCaracteresParseFloat(e.Procedimento) && LimpaCaracteresParseFloat(e.Valor_pg) > 0) {
        return e
      };
    });

    const res3 = Faturas.filter(e => {
      if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) > LimpaCaracteresParseFloat(e.Procedimento)) {
        return e
      };
    });

    const Valor1 = res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
    const Valor2 = res2.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
    const Valor3 = res2.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));

    const Valor4 = res3.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));
    const Valor5 = res3.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    var sum2 = 0;
    var sum3 = 0;
    var sum4 = 0;
    var sum5 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };
    for (var i = 0; i < Valor2.length; i++) {
      sum2 += Valor2[i];
    };
    for (var i = 0; i < Valor3.length; i++) {
      sum3 += Valor3[i];
    };
    for (var i = 0; i < Valor4.length; i++) {
      sum4 += Valor4[i];
    };
    for (var i = 0; i < Valor5.length; i++) {
      sum5 += Valor5[i];
    };
    const Diferenca = parseFloat(sum4) - parseFloat(sum5);
    const Soma = parseFloat(sum2) - parseFloat(sum3);
    const Calculo = parseFloat(Diferenca) - (parseFloat(sum1) + parseFloat(Soma));
    const Result = Calculo <= 0 ? 0 : Calculo;
    return Result;
  };
  //#endregion

  //#region FiltrarFaltas
  function FiltrarFaltas() {
    const res1 = Faturas.filter(e => {
      if (e.Status_comp === 'Faltou') {
        return e
      };
    });

    const Valor1 = res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };

    const Result = parseFloat(sum1);
    return Result;
  };
  //#endregion

  //#region FiltrarCortesia
  function FiltrarCortesia() {
    const res1 = Faturas.filter(e => {
      if (e.Cortesia === 'Sim') {
        return e
      };
    });

    const Valor1 = res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };

    const Result = parseFloat(sum1);
    return Result;
  };
  //#endregion

  //#region FiltrarQntdeConsultas
  function FiltrarQntdeConsultas() {
    const Result = Faturas.length < 10 ? '0' + Faturas.length : Faturas.length;
    return Result;
  };
  //#endregion

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {Loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Foto
      </div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setFoto(info.file.name);
    }
  };

  const [Pdf, setPdf] = useState(false);

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" danger onClick={() => setPdf(true)}>PDF</Button>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <Search
          style={{ marginLeft: 10, width: 350 }}
          placeholder="Buscar por nome"
          enterButton="Buscar"
          size="large"
          onSearch={onSearch}
        />
      </div>
      <div className='ContainerTable'>
        <Table dataSource={Pacientes} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar paciente" : "Novo paciente"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(!Novo), LimpaCampos()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Update() : Cadastrar()}>Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '100%' }}>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            action={Config.Url + 'UploadsPacientes'}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {Foto ? (
              <img
                src={Config.Url + 'Pacientes/' + Foto}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 10
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <div>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: 330, marginBottom: 10 }} placeholder="Nome" />
            <Input value={Nascimento} onChange={(e) => setNascimento(e.target.value)} size={'large'} style={{ width: 330 }} type="date" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <Input value={Cpf} onChange={(e) => setCpf(CPFValid.format(e.target.value))} size={'large'} style={{ width: '48%', color: CPFValid.isValid(Cpf) ? 'white' : 'black', backgroundColor: CPFValid.isValid(Cpf) ? 'green' : 'white' }} placeholder="Cpf" />
          <Select value={Sexo} onSelect={(e) => setSexo(e)} size={'large'} style={{ width: '48%' }} placeholder="Sexo" >
            <options value="">Sexo</options>
            <options value="Masculino">Masculino</options>
            <options value="Feminino">Feminino</options>
            <options value="Outros">Outros</options>
          </Select>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <Input value={Telefone} onChange={(e) => setTelefone(e.target.value)} size={'large'} style={{ width: '48%' }} placeholder="Telefone" />
          <Select value={Whatsapp} onSelect={(e) => setWhatsapp(e)} size={'large'} style={{ width: '48%' }} placeholder="Whatsapp" >
            <options value={null}>Whatsapp</options>
            <options value={true}>Sim</options>
            <options value={false}>Não</options>
          </Select>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <Input value={Endereco} onChange={(e) => setEndereco(e.target.value)} size={'large'} style={{ width: '75%' }} placeholder="Endereço" />
          <Input value={Numero} onChange={(e) => setNumero(e.target.value)} type='number' size={'large'} style={{ width: '21%' }} placeholder="N°" />
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <Input value={Bairro} onChange={(e) => setBairro(e.target.value)} size={'large'} style={{ width: '48%' }} placeholder="Bairro" />
          <Input value={Estado} onChange={(e) => setEstado(e.target.value)} size={'large'} style={{ width: '48%' }} placeholder="Estado" />
        </div>

        <Input value={Cidade} onChange={(e) => setCidade(e.target.value)} size={'large'} style={{ width: '102%', marginBottom: 10 }} placeholder="Cidade" />

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <Select value={EstadoCivil} onSelect={(e) => setEstadoCivil(e)} size={'large'} style={{ width: '48%' }} placeholder="Estado Civil" >
            <options value="">Estado Civil</options>
            <options value="Solteiro (a)">Solteiro (a)</options>
            <options value="Casado (a)">Casado (a)</options>
            <options value="Outros">Outros</options>
          </Select>
          <Input value={Profissao} onChange={(e) => setProfissao(e.target.value)} size={'large'} style={{ width: '48%' }} placeholder="Profissão" />
        </div>

        <Select value={Referencia} onSelect={(e) => setReferencia(e)} size={'large'} style={{ width: '102%', marginBottom: 10 }} placeholder="Referencia" >
          <options value="">Referencia</options>
          <options value="Solteiro (a)">Solteiro (a)</options>
        </Select>

      </Drawer>

      <Drawer
        title={Nome}
        closable={false}
        width={'100%'}
        placement="right"
        open={Fatura}
        extra={
          <Space>
            <Button onClick={() => [setFatura(!Fatura), LimpaCampos()]}>Fechar</Button>
            <Button type="primary" onClick={() => setNovoFatura(true)} >Adicionar</Button>
          </Space>
        }>

        <div style={{ width: '100%', height: 100, justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiCashRegister}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#b1ff00' }}>
              {numberToReal(FiltrarHistorico())}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Históricos
            </text>
          </div>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiCashMinus}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#ff0000' }}>
              {numberToReal(FiltrarDebitos())}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Débitos
            </text>
          </div>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiCashPlus}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#b1ff00' }}>
              {numberToReal(FiltrarCredito())}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Crédito
            </text>
          </div>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiCashRemove}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#f00000' }}>
              {numberToReal(FiltrarCortesia())}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Cortesia
            </text>
          </div>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiCashRemove}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#f00000' }}>
              {numberToReal(FiltrarFaltas())}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Faltas
            </text>
          </div>

          <div style={{ width: '15%', height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, backgroundColor: Config.COR1 }}>
            <Icon path={mdiClipboardList}
              size={2}
              color={Config.BRANCO}
            />
            <text style={{ fontSize: 15, fontWeight: 'bold', color: '#b1ff00' }}>
              {FiltrarQntdeConsultas()}
            </text>
            <text style={{ fontSize: 12, fontWeight: 'bold', color: Config.BRANCO }}>
              Frequência
            </text>
          </div>

        </div>

        <Table dataSource={Faturas} max columns={columnsFaturas} />

      </Drawer>

      <Drawer
        title={IdFatura !== '' ? "Editar fatura" : "Nova fatura"}
        closable={false}
        width={480}
        placement="right"
        open={NovoFatura}
        extra={
          <Space>
            <Button onClick={() => [setNovoFatura(false), LimpaCamposFatura()]}>Fechar</Button>
            <Button type="primary" onClick={() => IdFatura !== '' ? UpdateFatura() : CadastrarFatura()}>Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Status</label>
            <Select value={Status} onSelect={(e) => setStatus(e)} size={'large'} style={{ width: '100%' }} placeholder="Selecione o status" >
              <options value="">Selecione o status</options>
              <options value="Compareceu">Compareceu</options>
              <options value="Faltou">Faltou</options>
            </Select>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Título</label>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%' }} placeholder="Título" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Data</label>
            <Input value={Data} onChange={(e) => setData(e.target.value)} size={'large'} style={{ width: '100%' }} type='date' placeholder="Data" />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Valor do procedimento</label>
            <Input value={ValorProcedimento} onChange={(e) => setValorProcedimento(formatarMoeda(e.target.value))} size={'large'} style={{ width: '100%' }} placeholder="R$0,00" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          {Status === 'Compareceu' &&
            <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
              <label style={{ marginLeft: 5 }}>Foir pago ?</label>
              <Select value={Pago} onSelect={(e) => setPago(e)} size={'large'} style={{ width: '100%' }} placeholder="Pago ?" >
                <options value="">Pago ?</options>
                <options value="Sim">Sim</options>
                <options value="Não">Não</options>
              </Select>
            </div>
          }
          {Pago === 'Sim' && Status === 'Compareceu' &&
            <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
              <label style={{ marginLeft: 5 }}>Valor pago </label>
              <Input value={ValorPago} onChange={(e) => setValorPago(formatarMoeda(e.target.value))} size={'large'} style={{ width: '100%' }} placeholder="R$0,00" />
            </div>
          }
          {Pago === 'Não' && Status === 'Compareceu' &&
            <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
              <label style={{ marginLeft: 5 }}>Foi uma cortesia ?</label>
              <Select value={Cortesia} onSelect={(e) => setCortesia(e)} size={'large'} style={{ width: '100%' }} placeholder="Cortesia ?" >
                <options value="">Cortesia ?</options>
                <options value="Sim">Sim</options>
                <options value="Não">Não</options>
              </Select>
            </div>
          }
        </div>

        <div style={{ flexDirection: 'column', display: 'flex', width: '102%' }}>
          <label style={{ marginLeft: 5 }}>Observação</label>
          <Input.TextArea value={Observacao} onChange={(e) => setObservacao(e.target.value)} size={'large'} autoSize={{ minRows: 3, maxRows: 10 }} style={{ width: '102%', height: 200 }} placeholder="Obs" />
        </div>

      </Drawer>




      <Modal width={1000} open={Pdf} onCancel={() => setPdf(false)} footer={[]} closable={false}>
        <div style={{ height: 800 }}>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <div style={{ width: '100%', padding: 25, height: 180, backgroundColor: '#3c5270', alignItems: 'center' }}>
                  <Text style={{ color: '#fff', fontWeight: 'bold' }}>{localStorage.getItem('Empresa')}</Text>
                  <View style={styles.section}>
                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Profissional: {localStorage.getItem('Nome')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Telefone: {localStorage.getItem('Telefone')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Cidade: {localStorage.getItem('Cidade')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Gerado em: {moment().tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                </div>

                <View style={styles.table}>
                  <View style={styles.row}>
                    <div style={{ width: '20%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Nome</Text>
                    </div>
                    <div style={{ width: '20%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Nascimento</Text>
                    </div>
                    <div style={{ width: '20%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Cpf</Text>
                    </div>
                    <div style={{ width: '20%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Telefone</Text>
                    </div>
                    <div style={{ width: '20%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Sexo</Text>
                    </div>
                  </View>

                  {Pacientes.map((e, index) => (
                    <View style={styles.rows}>
                      <div style={{ width: '20%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Nome}</Text>
                      </div>
                      <div style={{ width: '20%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Nascimento}</Text>
                      </div>
                      <div style={{ width: '20%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Cpf}</Text>
                      </div>
                      <div style={{ width: '20%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Telefone}</Text>
                      </div>
                      <div style={{ width: '20%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Sexo}</Text>
                      </div>
                    </View>
                  ))}

                </View>

              </Page>
            </Document>
          </PDFViewer>
        </div>
      </Modal>

    </div>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  section: {
    marginTop: 25,
    width: '100%',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    paddingBottom: 8,
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    marginBottom: 1

  }
});


export default Pacientes;
